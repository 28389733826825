import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'

// https://medium.com/@jogarcia/dynamically-register-vue3-vite-components-54954b8fafea
const modules = import.meta.glob('@/components/_base-*.vue', { eager: true })

export default function loadComponents(app) {
  for (const path in modules) {
    const rawName = path.split('/').at(-1).split('.')[0]

    const componentName = upperFirst(
      camelCase(
        rawName
          // Remove the "./_" from the beginning
          .replace(/^\.\/_/, '')
          // Remove the file extension from the end
          .replace(/\.\w+$/, '')
      )
    )

    app.component(componentName, modules[path].default)
  }
}
