export const ROUTES = {
  HOME: 'home',
  LOGIN: 'login',
  OVERVIEW: 'overview',
  PROJECTS: 'projects',
  NEW: 'new',
  _404: '404',
}

export default [
  {
    path: '/',
    name: ROUTES.HOME,
    component: () => import('@/views/home-view.vue'),
  },
  {
    path: '/login',
    name: ROUTES.LOGIN,
    meta: { title: 'Login' },
    component: () => import('@/views/login-view.vue'),
  },
  {
    path: '/projects/:projectid/overview',
    name: ROUTES.OVERVIEW,
    meta: { title: 'Overview' },
    component: () => import('@/views/project-view.vue'),
  },
  {
    path: '/projects/:projectid/generate',
    name: ROUTES.NEW,
    meta: { title: 'Generate' },
    component: () => import('@/views/generate-view.vue'),
  },
  {
    path: '/projects/',
    name: ROUTES.PROJECTS,
    meta: { title: 'Projects' },
    component: () => import('@/views/projects-view.vue'),
  },
  {
    path: '/404',
    name: ROUTES._404,
    component: () => import('@/views/_404.vue'),
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: ROUTES._404,
  },
]
