<script setup>
import { ref } from 'vue'

defineOptions({ inheritAttrs: false })

const props = defineProps({
  round: { default: false, type: Boolean },
  outline: { default: false, type: Boolean },
  defaultOpen: { type: Boolean, default: false },
})

const open = ref(props.defaultOpen)
</script>

<template>
  <div class="flex flex-col">
    <div
      class="flex w-full flex-col gap-4 transition-colors duration-100"
      :class="{
        'rounded-lg': round,
        'rounded-b-none': round && open,
        'border border-gray-400/60': outline,
      }"
    >
      <button
        v-bind="$attrs"
        class="group flex w-full items-center gap-3"
        :class="{
          'hover:bg-gray-500/10': outline,
          'bg-gray-400/10': open && outline,
          'rounded-lg': round,
          'rounded-b-none': round && open && outline,
        }"
        @click="open = !open"
      >
        <div class="flex flex-1 items-center">
          <slot name="button"> Button slot </slot>
        </div>

        <div
          class="transition-all"
          :class="{
            'text-sortera-500 group-hover:text-gray-600': !open,
            'rotate-180 text-gray-600 group-hover:text-sortera-500': open,
          }"
        >
          <BaseIcon name="chevron-down" class="h-5" />
        </div>
      </button>
      <slot v-if="$slots.subtitle" name="subtitle" />
    </div>
    <div
      v-if="open"
      class="flex"
      :class="{
        'border border-t-0 border-gray-400/60': outline,
        'rounded-b-lg': round,
      }"
    >
      <slot> Default slot </slot>
    </div>
  </div>
</template>
