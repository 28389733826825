import { initializeApp } from 'firebase/app'
import { collection, getFirestore } from 'firebase/firestore'

export const firebaseApp = initializeApp({
  projectId: 'generatorn-88db7',
  apiKey: 'AIzaSyDWBbC8LXHjSm9k8IwuWpK2xxbT3Sb1aJM',
})

const db = getFirestore(firebaseApp)

export const inboxRef = collection(db, 'inbox')
export const outboxRef = collection(db, 'outbox')
export const projectsRef = collection(db, 'projects')
export const usersRef = collection(db, 'users')
