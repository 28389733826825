import axios from 'axios'

const getSavedState = key => JSON.parse(window.localStorage.getItem(key))
const saveState = (key, state) => window.localStorage.setItem(key, JSON.stringify(state))

export const wrapUrl = url => {
  if (process.env.NODE_ENV === 'production') {
    return url
  }

  return 'https://corsproxy.io/?' + encodeURIComponent(url)
}

export const validateToken = () =>
  new Promise((resolve, reject) => {
    const token = getSavedState('token')
    if (!token) {
      reject('No token found')
    } else {
      axios
        .post(
          wrapUrl('https://generatorn.com/wp-json/jwt-auth/v1/token/validate'),
          {},
          { headers: { Authorization: 'Bearer ' + token } }
        )
        .then(({ data }) => {
          if (data.data.status === 200) {
            resolve(token)
          } else {
            reject(data.message)
          }
        })
        .catch(error => {
          reject(error)
        })
    }
  })

export const login = (username, password) =>
  new Promise((resolve, reject) => {
    axios
      .post(wrapUrl('https://generatorn.com/wp-json/jwt-auth/v1/token'), {
        username,
        password,
      })
      .then(({ data }) => {
        saveState('token', data.token)
        resolve(data)
      })
      .catch(error => {
        reject(error)
      })
  })
