import { createPinia } from 'pinia'
import { createApp } from 'vue'

import { firebaseApp } from '@/plugins/firebase-db' // do not move this import

import { VueFire, VueFireAuth } from 'vuefire'

import loadComponents from '@/components/_register-base-components'

import App from './App.vue'
import router from './router'

const app = createApp(App)

app.use(VueFire, { firebaseApp, modules: [VueFireAuth()] })

app.use(createPinia()) // this has to be before router!

app.use(router)

loadComponents(app)

app.mount('#app')
