<script setup>
import { storeToRefs } from 'pinia'

import { ROUTES } from '@/router/routes'
import { useAppStore } from '@/stores/app'

const store = useAppStore()
const { currentUser } = storeToRefs(store)

const signout = () => {
  window.localStorage.removeItem('token')
  window.location.href = '/'
}
</script>

<template>
  <div class="navbar bg-base-100">
    <div class="navbar-start">
      <div class="dropdown">
        <div tabindex="0" role="button" class="btn btn-ghost lg:hidden">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h8m-8 6h16"
            />
          </svg>
        </div>
        <ul
          class="menu dropdown-content menu-sm z-[1] mt-3 w-52 rounded-box bg-base-100 p-2 shadow"
        >
          <li>
            <router-link
              :to="{
                name: ROUTES.HOME,
              }"
            >
              Home
            </router-link>
          </li>
        </ul>
      </div>
      <a class="logo h-14 w-14"></a>
    </div>
    <div class="navbar-center hidden lg:flex">
      <ul class="menu menu-horizontal px-1">
        <li>
          <router-link
            :to="{
              name: ROUTES.HOME,
            }"
          >
            Home
          </router-link>
        </li>
      </ul>
    </div>
    <div class="navbar-end"> <a v-if="currentUser" class="btn" @click="signout">Logout</a> </div>
  </div>
</template>

<style>
.logo {
  background-image: url('@/assets/logo.png');
  background-size: cover;
  background-position: center;
}
</style>
