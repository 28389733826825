<script>
//https://github.com/eliep/vue-avatar

const getInitials = spacedName => {
  let parts = spacedName.split(/[ -]/)
  let initials = ''

  for (var i = 0; i < parts.length; i++) {
    initials += parts[i].charAt(0)
  }

  if (initials.length > 3 && initials.search(/[A-Z]/) !== -1) {
    initials = initials.replace(/[a-z]+/g, '')
  }

  initials = initials.substring(0, 3).toUpperCase()

  return initials
}

export default {
  props: {
    spacedName: { type: String, default: null },
    backgroundColor: { type: String, default: null },
    color: { type: String, default: null },
    customStyle: { type: Object, default: null },
    src: { type: String, default: null },
    square: { type: Boolean, default: false },
    round: { type: Boolean, default: false },
    mask: { type: Boolean, default: false },
    lighten: { type: Number, default: 130 },
    parser: {
      type: Function,
      default: getInitials,
      validator: parser => typeof parser('John', getInitials) === 'string',
    },
  },

  data() {
    return {
      backgroundColors: [
        '#F44336',
        '#FF4081',
        '#9C27B0',
        '#673AB7',
        '#3F51B5',
        '#2196F3',
        '#03A9F4',
        '#00BCD4',
        '#009688',
        '#4CAF50',
        '#8BC34A',
        '#ADB93A',
        /* '#FFEB3B' , */ '#FFC107',
        '#FF9800',
        '#FF5722',
        '#795548',
        '#9E9E9E',
        '#607D8B',
      ],
      imgError: false,
    }
  },

  mounted() {
    if (!this.isImage) {
      this.$emit('avatar-initials', this.spacedName, this.userInitial)
    }
  },

  computed: {
    background() {
      if (!this.isImage) {
        return (
          this.backgroundColor ||
          this.randomBackgroundColor(this.spacedName.length, this.backgroundColors)
        )
      }

      return null
    },

    fontColor() {
      if (!this.isImage) {
        return this.color || this.lightenColor(this.background, this.lighten)
      }

      return null
    },

    isImage() {
      return !this.imgError && Boolean(this.src)
    },

    style() {
      const style = {
        userSelect: 'none',
      }

      const imgBackgroundAndFontStyle = {
        background: `transparent url('${this.src}') no-repeat scroll 0% 0% / auto auto content-box border-box`,
      }

      const initialBackgroundAndFontStyle = {
        backgroundColor: this.background,
        color: this.fontColor,
      }

      const backgroundAndFontStyle = this.isImage
        ? imgBackgroundAndFontStyle
        : initialBackgroundAndFontStyle

      Object.assign(style, backgroundAndFontStyle)

      return style
    },

    userInitial() {
      if (!this.isImage) {
        return this.parser(this.spacedName, getInitials)
      }
      return null
    },
  },

  methods: {
    initial: getInitials,

    onImgError() {
      this.imgError = true
    },

    randomBackgroundColor(seed, colors) {
      return colors[seed % colors.length]
    },

    lightenColor(hex, amt) {
      // From https://css-tricks.com/snippets/javascript/lighten-darken-color/
      var usePound = false

      if (hex[0] === '#') {
        hex = hex.slice(1)
        usePound = true
      }

      var num = parseInt(hex, 16)
      var r = (num >> 16) + amt

      if (r > 255) r = 255
      else if (r < 0) r = 0

      var b = ((num >> 8) & 0x00ff) + amt

      if (b > 255) b = 255
      else if (b < 0) b = 0

      var g = (num & 0x0000ff) + amt

      if (g > 255) g = 255
      else if (g < 0) g = 0

      return (
        (usePound ? '#' : '') + String('000000' + (g | (b << 8) | (r << 16)).toString(16)).slice(-6)
      )
    },
  },
}
</script>

<template>
  <div
    class="vue-avatar--wrapper flex items-center justify-center"
    :class="{
      'rounded-full': !mask && round,
      rounded: !mask && !round && !square,
      'mask mask-squircle': mask && !square,
    }"
    :style="[style, customStyle]"
    aria-hidden="true"
  >
    <!-- this img is not displayed; it is used to detect failure-to-load of div background image -->
    <img v-if="isImage" style="display: none" :src="src" @error="onImgError" />
    <span v-show="!isImage" class="font-semibold">{{ userInitial }}</span>
  </div>
</template>
