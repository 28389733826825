<script setup>
import { jwtDecode } from 'jwt-decode'
import { useRoute, useRouter } from 'vue-router'

import { usersRef } from '@/plugins/firebase-db'
import { doc, getDoc } from 'firebase/firestore'

import NavBar from '@/components/nav-bar.vue'
import { ROUTES } from '@/router/routes'
import { useAppStore } from '@/stores/app'
import { validateToken } from '@/utils/auth'

const store = useAppStore()
const route = useRoute()
const router = useRouter()

validateToken()
  .then(async token => {
    const decoded = jwtDecode(token)
    const id = decoded.data.user.id

    const document = await getDoc(doc(usersRef, id))
    store.$patch({ currentUser: document.exists() ? document.data() : null })

    if (route.name === ROUTES.HOME) router.push({ name: ROUTES.PROJECTS })
  })
  .catch(() => router.push({ name: ROUTES.HOME }))
</script>

<template>
  <div class="flex min-h-screen w-full flex-col items-center">
    <template v-if="!store.currentUser && route.name !== ROUTES.HOME">
      <div class="loading loading-lg mx-auto mt-16" />
    </template>
    <template v-else>
      <NavBar />
      <div class="flex w-full max-w-2xl pt-6">
        <router-view :key="$route.fullPath" class="flex-1" />
      </div>
    </template>
  </div>
</template>
