<script setup>
const props = defineProps({
  id: {
    required: true,
    type: String,
  },
  title: {
    default: null,
    type: String,
  },
  fullHeight: {
    default: false,
    type: Boolean,
  },
})
</script>

<template>
  <dialog :id="props.id" class="modal">
    <form method="dialog" class="modal-box" :class="{ 'h-full': props.fullHeight }">
      <div class="flex h-full flex-col gap-4">
        <div class="flex items-center justify-between">
          <h3>
            <template v-if="props.title">
              {{ props.title }}
            </template>
          </h3>
          <div class="flex items-center gap-2">
            <span class="text-sm">Close</span>
            <button class="btn btn-circle btn-ghost btn-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-6 w-6"
              >
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
        <div class="flex flex-1 flex-col gap-6">
          <slot />
        </div>
      </div>
    </form>
    <form method="dialog" class="modal-backdrop">
      <button>x</button>
    </form>
  </dialog>
</template>
