<script setup>
const props = defineProps({
  type: {
    type: String,
    default: null,
    validator: value => {
      return ['success', 'error', 'warning', 'info'].includes(value)
    },
  },
  title: {
    type: String,
    default: null,
  },
})
</script>

<template>
  <div
    class="flex flex-1 items-stretch gap-2 rounded-lg border p-4"
    :class="{
      'border-success bg-success/20 text-success-content dark:text-success':
        props.type === 'success',
      'border-error bg-error/20 text-error-content': props.type === 'error',
      'border-warning bg-warning/10 text-warning-content dark:text-warning':
        props.type === 'warning',
      'border-info bg-info/20': props.type === 'info',
      'border-gray-400 bg-gray-400/20': !props.type,
    }"
  >
    <div class="flex flex-col gap-1">
      <div class="text-xs font-semibold" v-if="props.title">
        {{ props.title }}
      </div>
      <div class="flex h-full items-center gap-2 text-sm">
        <slot />
      </div>
    </div>
  </div>
</template>
